import React from 'react'
import {Container, Flex, css} from 'theme-ui'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ListItem from "@elegantstack/solid-ui-components/src/ListItem";
import Reveal from '@solid-ui-components/Reveal'

const ContentList = ({content: {collection}, reverse}) => (
    <Container>
     
        <Flex
            sx={{
                flexDirection: [
                    reverse ? `column-reverse` : `column`
                ],
                marginLeft: `1rem`
            }}
        >
                 <Reveal
                  effect='fadeInRight'
                  delay={1}
                  css={css({ mb: [4, null, null, 0] })}
                >
            {collection && (
                <>
                    {collection.map((props, index) => (
                        <ListItem key={`item-${index}`} {...props} compact/>
                    ))}
                </>
            )}
            </Reveal>
        </Flex>
     
    </Container>
)

export default WithDefaultContent(ContentList)
